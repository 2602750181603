.ant-cascader-dropdown {
  width: 300px;
}

.vlx-select-aligned {
  display: flex;
  align-items: center;
}

.add-appointment-form__doctor-field {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.patient-select
  .ant-select-selector
  .ant-select-selection-overflow {
    padding-inline-end: 40px!important;
    .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      padding-inline-end: 10px;

      .ant-select-selection-item-content {
        height: 24px;
        line-height: 24px;
      }

      .ant-select-selection-item-remove {
        vertical-align: 6px;
        width: 16px;
      }
    }
  }
}

.add-appointment-form__multi-select-field .ant-select-selection-overflow {
  max-width: calc(100% - 44px);

  .ant-select-selection-item-remove {
    width: 20px;
  }
}

@media (max-width: 768px) {
  body:has(.mobile-custom-dropdown.ant-picker-focused) {
    .ant-picker-dropdown {
      width: 100vw !important;
      height: 300px !important;
      position: fixed;
      left: 0 !important;
      bottom: 0 !important;
      top: unset !important;
      z-index: 99999;

      .ant-picker-panel-container {
        height: 100%;
      }

      .ant-picker-panel-layout {
        justify-content: center;
        align-items: center;
      }
    }

    .ant-picker-time-panel-column {
      overflow-y: auto !important;
    }
  }
}

.appointment-room-select-field {
  .ant-select-selection-item {
    padding-inline-end: 10px !important;
    & > div {
      overflow: hidden;
      width: 100%;
    }
  }
}

.add-appointment-form .ant-form-item:has(#event_notes) {
  margin-bottom: 0;
}