.fiscal-checkbox-form-item {
  width: 200px;

  .ant-form-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-form-item-label {
      margin-right: 5px;
      padding-bottom: 0;
    }

    .ant-form-item-control {
      width: 10px;
    }
  }
}
