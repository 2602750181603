@import '../../assets/sass/variables';

.ant-input-number-input-wrap {
  height: 40px;
  width: 100%;
}
.sale-form .personal-info-card {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.price-label {
  font-weight: 600;
  font-size: '16px';
}

.sale-form {
  .sale-row {
    .ant-input-number {
      @media (max-width: 768px) {
        height: 40px;
        min-height: 40px;
      }
    }

    @media (max-width: 768px) {
      .mobile-one-row-field {
        .ant-form-item-row {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;

          .ant-form-item-label {
            padding-bottom: 0;
            max-width: 70%;
            width: fit-content;
          }

          .ant-col.ant-form-item-control {
            max-width: 30%;
          }
        }
      }
      .ant-trash {
        min-width: 100%;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
          margin-top: 5px;
        }
      }
    }
  }
}

.sale-details-form-items {
  padding-left: 5px;
  margin-top: 10px;
}

.sale-details-cards-container {
  @media (min-width: 768px) {
    top: 64px;
    background-color: white;
    z-index: 1;
  }
}

.card-content--client-info .ant-col span,
.card-content--client-info .ant-col a {
  font-size: 16px;
}

.sale-form {
  &__container {
    .fiscal-checkbox-form-item .ant-row {
      flex-wrap: wrap;

      @media screen and (min-width: 1200px) {
        flex-wrap: nowrap;
      }

      .ant-col {
        flex: unset;
      }
    }

    .ant-input-number-disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }

    @media screen and (min-width: 1200px) {
      .sale-form__container__left-side + .sale-form__container__right-side {
        margin-left: 20px;
      }

      &__left-side {
        max-width: 400px;
        width: 33.33%;
        flex: unset;

        .ant-card {
          position: sticky;
          top: 70px;
        }

        margin-left: 10px;
      }

      &__right-side {
        width: auto;
        flex: 1;
        max-width: calc(66.6% - 36px);
      }
    }

    @media screen and (min-width: 1450px) {
      &__right-side {
        width: calc(100% - 430px);
        flex: 1;
        max-width: calc(100% - 430px);
      }
    }
  }

  &__save-buttons-bottom {
    display: block;

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  &__save-buttons-left-side {
    display: none;

    @media screen and (min-width: 1200px) {
      padding-top: 10px;
      display: block;
    }
  }

  &__top-cont {
    margin-top: 10px;

    &__count-item {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 5px;

      &__cont {
        display: flex;
        align-items: center;

        &__val {
          font-weight: 600;
          font-size: 16px;
          color: #000;
          text-align: right;
          width: fit-content;
          max-width: 100%;
        }
      }
    }

    &__date {
      .ant-form-item-row {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        .ant-form-item-label {
          margin-bottom: 0;
          margin-right: 15px;
        }

        .ant-form-item-control {
          width: auto !important;
        }
      }
    }

    &__add-new-client-btn {
      // @media (max-width: 768px) {
      padding-right: 10px;
      padding-left: 10px;
      // }

      .ant-btn-icon {
        display: block;
        font-weight: 400;
        margin-inline-end: 0 !important;
        margin: 0;
      }

      .create-client-txt {
        display: none;
      }

      // @media (min-width: 768px) {
      //   .create-client-txt {
      //     display: block;
      //   }

      //   .ant-btn-icon {
      //     display: none;
      //   }
      // }
    }

    &__client-select {
      margin-right: 10px;
      flex: 1;
      width: calc(100% - 72px);
    }
  }
}

.inventory-update-buttons {
  z-index: 998;

  .ant-btn.ant-btn-circle {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.sale-form__top-cont__count-item {
  font-weight: 600;
  font-size: 16px;
}

.action-buttons {
  @media screen and (max-width: 423px) {
    justify-content: end;
  }
}

.unsaved-changes {
  @media screen and (max-width: 423px) {
    margin-bottom: 10px;
  }
}
