@import '../../../assets/sass/variables';

.logo {
  align-items: center;
  color: inherit;
  display: flex;
  height: 42px;
  padding: 0;
  position: relative;
  text-decoration: none;

  .logo-img {
    height: 100%;
    max-height: 100%;
    width: auto;
  }
  .logo-text {
    display: block;
    font-size: 20px;
    line-height: 1;
    margin-left: ($module-rem * 2);
  }
}
