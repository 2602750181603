.vlx-modal-footer {
  border-top: 1px solid #DCE0E4;
  padding: 19px 24px;
  gap: 20px;
  .wrong-form-text {
    color: #8C8C8C;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
  &__buttons-container.ant-row {
    height: 40px;
    gap: 16px;
    .submit-form-item {
      height: 40px;
    }
    button {
      box-shadow: none;
    }
  }
}