.search-filters-horizontal {

  .row {
    margin: 10px 1px;
    align-items: baseline;

    .ant-btn.ant-btn-sm {
      margin: 10px 15px;
    }

    .ant-form {
      display: flex;
      flex-direction: row;
    }
  }
}
