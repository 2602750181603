.add-new-patient__owner-select {
    .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
        overflow: hidden;
        position: relative;
        display: block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 38px;
        padding-inline-end: 40px;
    }
}

.add-service-form-stock__name {
    .ant-select-selection-item {
        max-width: 90%;
    }
}

.add-service-form-stock-container {
    flex-wrap: nowrap!important;
    .ant-row.ant-form-item-row {
        flex-direction: column!important;
    }
    .ant-col.ant-form-item-control {
        width: 100%!important;
    }
    &__row {
        align-items: center;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }

        >.ant-col {
            padding-right: 15px;

            .ant-checkbox-wrapper {
                margin-top: 29px;

                @media (max-width: 767px) {
                    margin-top: 0;
                }
            }
        }

        .measurement-quantity-container {
            display: flex;
            align-items: center;

            .ant-form-item {
                width: 100%;
                max-width: 180px;
            }

            .measurement-label {
                margin-left: 10px;
            }
        }

        .included-in-price-container {
            @media (max-width: 767px) {
                margin-top: 10px;
            }
        }

        &:first-child {

            .measurement-quantity-container {
                .measurement-label {
                    margin-top: 16px;
                }
            }

            button {
                margin-top: 19px;

                @media (max-width: 767px) {
                    margin-top: 0;
                }
            }
        }

        &:nth-child(n + 2) {
            .ant-col.ant-form-item-label label {
                display: none;

                @media (max-width: 767px) {
                    display: block;
                }
            }

            .ant-checkbox-wrapper {
                margin-top: 8px;
            }

            button {
                margin-bottom: 10px;
                margin-top: 8px;
            }
        }
    }

    .ant-form-item {
        margin-bottom: 10px;
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: #2D5274;
        border-color: #2D5274;
    }

    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
        border-color: #2D5274;
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        background-color: #2D5274;
        border-color: #2D5274;
    }

    .ant-checkbox {
        border-radius: 3px;

        &:hover {
            background-color: #2D5274;
            border-color: #2D5274;
        }

        &-checked .ant-checkbox-inner {
            background-color: #0e3048;
            border-color: #0e3048;
        }
    }

}

.services-stock-list-select.ant-select-item-option-disabled {
    display: none;
}

.ant-form-item-control-input-content .ant-select .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
    margin-right: 3px;
}


#addNewServiceGroupBtn {
    padding-left: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.ant-select-dropdown:has(#addNewServiceGroupBtn) {
    padding: 0;
}