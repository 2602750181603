.time-cell-row {
  span {
    line-height: unset;
    margin-right: 6px;
  }
}

.ant-table-cell {
  padding-right: 2px !important;
}

.appointments-list-table table {
  @media only screen and (min-width: 992px) {
    table-layout: fixed !important;
  }

  @media only screen and (max-width: 991px) {
    tr td:first-child {
      max-width: 200px;
    }
  }

  .ant-table-row:hover {
    cursor: pointer;
  }
}

.change-appointment-status-button {
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
}

.search-field {
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 20px;
  margin-top: 10px;

  .ant-input-search-button {
    height: 42px !important;
  }

  .ant-input-affix-wrapper {
    height: 42px;
    border-start-start-radius: 20px !important;
    border-end-start-radius: 20px !important;
  }

  .ant-input-search-button {
    height: 40px;
    width: 50px;
    border-start-end-radius: 20px !important;
    border-end-end-radius: 20px !important;
  }
}

.ant-popover {
  max-width: 250px;
}
