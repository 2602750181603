.dictionary-search-field {
  max-width: 400px;
  min-width: 250px;
  margin-bottom: 5px;
  margin-top: 5px;

  .ant-input-search-button {
    height: 42px !important;
  }

  .ant-input-affix-wrapper {
    height: 42px;
    border-start-start-radius: 20px !important;
    border-end-start-radius: 20px !important;
  }

  .ant-input-search-button {
    height: 40px;
    width: 50px;
    border-start-end-radius: 20px !important;
    border-end-end-radius: 20px !important;
  }
}
