.locale-dropdown {
  max-width: 70px;
  .ant-select-selector{
    background: none !important;
  }

  .ant-select-selector:hover {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .ant-select-selector:focus {
    border: none !important;
  }
}
