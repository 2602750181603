.clinic-avatar {
  border-radius: 50px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  // background-image: url('../../assets/img/avatar_paw.svg');
}

.avatar-modal-btns-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.avatar-editor-container {
  display: flex;
  justify-content: center;

  & > div > div {
    overflow-x: auto;
    // max-width: 750px;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }
}

.ant-card-body .ant-upload-wrapper {
  justify-content: center;
  display: flex;

  .ant-upload {
    margin-bottom: 0;
    margin-inline-end: 0;
  }
}
