.bills-list-table .ant-table-thead tr .ant-table-cell:first-child .ant-table-column-sorters .ant-table-column-sorter,
.payments-list-table .ant-table-thead tr .ant-table-cell:first-child .ant-table-column-sorters .ant-table-column-sorter {
    margin: 0 auto;

    .ant-table-column-sorter-inner {
        margin-left: 0 !important;
    }
}

.bills-list-table .ant-table-thead tr .ant-table-cell:first-child,
.payments-list-table .ant-table-thead tr .ant-table-cell:first-child {
    padding-left: 10px;

    .ant-table-column-title {
        display: none;
    }
}

.bills-list-table .ant-table-tbody .ant-table-row .ant-table-cell:first-child,
.payments-list-table .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
    padding-left: 10px;
}

.bills-list-table table,
.financial-accounts-list-table table,
.payments-list-table table {
    .ant-table-row:hover {
        cursor: pointer;
    }
}

.left-column {
    margin-left: 10px;
}

.bills-payments-list-table {
    margin-top: 20px;
}

.finances-list-income-icon {
    width: 20px;
    margin: 0 auto;
    display: flex;
    min-width: 18px;
}

.transfer-button-table-view {
    border-radius: 30px;
    height: 45px;
    &.ant-btn.ant-btn-primary:not(.ant-btn-disabled):focus {
        background: #0e3048;
    }
}