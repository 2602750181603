.employees-edit-form-buttons-row {
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.invitation-creation-form-row {
  height: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 1.6rem;

  .ant-form-item {
    margin-bottom: 3px;
  }
}
