.name-statuses-form-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .ant-btn  {
    margin-left: 20px;
  }
}

.invoice-row-container {
  display: flex;
  flex-direction: column;

  &__form {
    .inventory-update-buttons {
      z-index: 0;
    }
    .documents-tab {
      min-height: fit-content;
    }
  }

  &__table {
    margin-top: 50px;

    table {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 1500px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    &__form {
      width: 100%;
    }

    &__table {
      width: 100%;
    }
  }

  &__add-item-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.invoice-details-top-container__buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  .ant-btn {
    height: 45px!important;
    &.inventory-update-buttons.ant-btn.ant-btn-circle {
      width: 45px!important; 
      span {
        font-size: 18px;
      }
    }
  }
}