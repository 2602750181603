@import '../../assets/sass/variables';

.services-list-table {
    .ant-table-row:hover {
        cursor: pointer;
    }
}

.capitalize-first-letter {
    display: inline-block;

    &:first-letter {
        text-transform: capitalize;
    }
}