.inventory-info-page {
    &__statistics-box {
        max-width: max-content;
        gap: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        // .ant-statistic {
        //     min-width: 130px;
        // }
    }
}