.quill {
  &.full-length-view {
    width: 100%;
  }

  &.row-3 {
    .ql-editor {
      min-height: 80px;
    }
  }

  &.row-4 {
    .ql-editor {
      min-height: 98px;
    }
  }

  &.row-5 {
    .ql-editor {
      min-height: 116px;
    }
  }

  &.row-6 {
    .ql-editor {
      min-height: 134px;
    }
  }

  .ql-container {
    border-radius: 0 0 20px 20px;

    &.ql-disabled {
      border-radius: 5px;

      .ql-editor {
        overflow: hidden;
        margin: 0;
        height: 100%;
      }
    }
  }

  .ql-toolbar {
    border-radius: 20px 20px 0 0;
  }

  .ql-editor.scrollable {
    max-height: 393px;
  }

  .ql-editor h3 {
    font-size: 1.5em;
  }
}
