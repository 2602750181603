.affix-position {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 2000px;
}

.submit-form-item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 8px;
  margin-bottom: 0px;
}

.inventory-update-buttons {
  z-index: 998;

  .ant-btn.ant-btn-circle {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}