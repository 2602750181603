@import '../../assets/sass/_variables.scss';

.calendar {
  .ant-picker-calendar-header {
    padding-inline-start: 0px;
  }
}

.calendar-filter-panel {
  .ant-collapse-content {
    max-height: 400px;
    overflow: auto;
  }
}

.custom-event-wrapper {
  .rbc-event {
    border: 1px solid #265985;
    background-color: #ffffff;
    border-left-width: 12px;
    color: #0000009c;
  }
}
.custom-view {
  .rbc-header {
    min-width: 300px;
    max-width: none;
  }

  .rbc-time-content {
    overflow-x: auto;

    .rbc-day-slot {
      min-width: 300px;
    }
  }
}

.rbc-time-view-resources {
  .rbc-event-label {
    display: none; /* Hides the default time label */
  }
}

.rbc-time-view {
  scrollbar-width: thick;
  scrollbar-color: #888 #f5f5f5;
}

.rbc-time-view::-webkit-scrollbar {
  height: 16px;
}

.rbc-time-view::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.rbc-time-view::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.rbc-today {
  background-color: accent-palette(350);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: accent-palette(500);
  border-color: accent-palette(800) !important;
  z-index: 1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: accent-palette(500);
  border-color: accent-palette(800);
  z-index: 1;
}

.ant-picker-calendar {
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: accent-palette(800);
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled)
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: accent-palette(500);
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: accent-palette(800);
}

.ant-radio-button-wrapper:hover {
  color: accent-palette(800);
}

.event-modal-text-row {
  margin-bottom: 10px;
  &__img-cont {
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    img {
      max-height: 16px;
    }
  }
}

.event-modal-buttons {
  display: flex;
  justify-content: end;
  .ant-row {
    gap: 10px;
  }
  .ant-btn.ant-btn-circle {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
}

.custom-event-header-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

  .avatar-container {
    flex-shrink: 0;
    margin-right: 15px; // Space between avatar and text
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .avatar-placeholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
  }

  .info-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .label {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }

    .title {
      font-size: 12px;
      color: #999;
      text-align: left;
    }
  }
}
