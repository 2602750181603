.long-value-form-item .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item,
.long-value-form-item .ant-form-item-control-input-content .ant-select .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
}

.checkbox-disabled{
    .ant-checkbox-inner {
        background-color: rgba(0, 0, 0, 0.04);
        border-color: #d9d9d9;
    }
}

.appointment-inventory-items-form {
    display: flex;

    h3 {
        margin-top: 34px;
    }

    .inventory-update-buttons {
        z-index: 0;
        margin-top: 30px;
    }

    .ant-col label,
    .ant-col h3 {
        overflow: hidden;
        position: relative;
        display: block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.item-form-select .ant-select-item-option-content {
    white-space: normal;
}

.flat-checkbox-form-item {
    width: 200px;

    .ant-form-item-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .ant-form-item-label {
            margin-right: 5px;
            padding-bottom: 0;
        }

        .ant-form-item-control {
            width: 10px;

            .ant-checkbox-wrapper{
                display: flex;
            }
        }
    }
}


