.add-tool-form__single-select-field {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.add-tool-form__multi-select-field .ant-select-selection-overflow {
  max-width: calc(100% - 44px);

  .ant-select-selection-item-remove {
    width: 20px;
  }
}

.disabled-select {
  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.04);
    &:hover {
      box-shadow: none;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}

.one-line-checkbox-form-item {
  width: 200px;

  .ant-form-item-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .ant-form-item-label {
      margin-right: 15px;
      padding-bottom: 0;
    }

    .ant-form-item-control {
      width: 10px;
    }
  }
}

.default-price .ant-row.ant-form-item-row {
  .ant-col.ant-form-item-label {
    flex: none;
  }
  .ant-col.ant-form-item-control {
    flex: none;
  }
}

.purchase-price-form-item {
  width: 70%;
  padding-right: 5px;
  display: inline-block;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-col {
  min-width: 300px;
  max-width: 500px;
}

.ant-form-item.default-price {
  width: 300px;
}

.horizontal-label-form-item.blacklisted-container {
  .ant-row.ant-form-item-row {
    .ant-col.ant-form-item-label {
      flex: none;
    }
    .ant-col.ant-form-item-control {
      flex: none;
    }
  }
}

.ant-btn.ant-btn.ant-btn-default.packaging-type-btn {
  background-color: white;
  color: black;
  margin-bottom: 10px;

  &:focus {
    background-color: #0e3048;
  }
  &.left {
    border-radius: 5px 0 0 5px;
  }
  &.right {
    border-radius: 0 5px 5px 0;
  }
  &.active {
    background-color: #0e3048;
    color: white;
  }
}

.packaging-type-hint {
  color: grey;
}

.stock-modal-leftover {
  .ant-row .ant-col {
    width: fit-content !important;
    max-width: fit-content !important;
  }
}
