.stock-list {
    .ant-table-row:hover {
        cursor: pointer;
    }
}

.stock-list-price-item-tooltip.ant-tooltip {
    max-width: fit-content;
    .ant-tooltip-content .ant-tooltip-inner {
        .ant-list {
            .ant-list-header {
                padding-top: 7px;
                padding-bottom: 0;
            }
            .ant-list-header span, .ant-list-items li {
                color: white;
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }
}

.stock-list-buttons-container {
    gap: 15px;
    &:has(.ant-affix) {
        .stock-list-buttons-container__print {
            right: 60px;
        }
    }
 }