.time-cell-row {
  span {
    line-height: unset;
    margin-right: 6px;
  }
}

.time-cell-row-container {
  min-width: 110px;
}

.collapse-header-row {
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.appointments-list-table table {
  @media only screen and (min-width: 992px) {
    table-layout: fixed !important;
  }

  @media only screen and (max-width: 991px) {
    tr td:first-child {
      max-width: 200px;
    }
  }

  .ant-table-row:hover {
    cursor: pointer;
  }
}

.appointment-details-top-container {
  margin-top: 20px;

  &__left-column {
    border-right: 1px solid rgba(5, 5, 5, 0.1);
  }

  &__right-column {
    padding-left: 10px;
  }

  &__edit-appointmnet-info {
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 20px;
  }

  @media only screen and (max-width: 767px) {
    &__right-column {
      padding-left: 0;
      margin-top: 20px;
    }
    &__left-column {
      border: none;
    }
  }
}

.icon-style {
  width: 50px;
  font-size: 30px;
  color: #171919;
}

.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.cards-container .ant-card {
  flex-grow: 1;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.appointment-items-collapse-header {
  .ant-collapse-item {
    .ant-collapse-header {
      display: flex;
      align-items: center;
    }
  }
}

.collapse-header-row {
  width: 100%;

  .ant-col {
    display: flex;
    align-items: center;
  }

  .ant-typography {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.change-status-basic-button {
  border-radius: 30px;
  white-space: normal;
  background-color: #ffdd80 !important;
  border-color: #ffdd80 !important;
  color: #0e3048 !important;
  margin-bottom: 5px;

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 0.8;
    color: #dfdfdf;
  }
}

.cancel-button {
  border-radius: 30px;
  white-space: normal;
  background: #daf2ec !important;
  color: #0e3048 !important;
  border-color: #daf2ec !important;
  margin-bottom: 5px;
  justify-content: flex-end;
}

.ant-card-bordered {
  border-radius: 10px 10px 10px 10px;
}

.client-container {
  height: calc(100% - 20px);
}

.flat-checkbox-form-item {
  width: 200px;

  .ant-form-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-form-item-label {
      margin-right: 5px;
      padding-bottom: 0;
    }

    .ant-form-item-control {
      width: 10px;
    }
  }
}

.appointment-services-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    width: 40px !important;
    height: 40px !important;
    font-size: 15px !important;
  }
}

.appointment-container {
  padding: '0 12px';
  margin-top: 20px;
  @media screen and (max-width: 450px) {
    padding: 0;
  }
}

.personal-info-card {
  @media screen and (max-width: 995px) {
    padding-bottom: 20px;
  }
}

.services-total-price-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
}
