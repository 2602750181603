.transfer {
  .ant-transfer-list-pagination {
    text-align: center !important;
  }

  .ant-transfer-list-search-action {
    top: 17px !important;
  }

  .ant-btn.ant-btn-sm {
    padding: 0 !important;
  }
}
