.name-statuses-form-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.audit-row-container {
  display: flex;
  flex-direction: column;

  &__form {
    .inventory-update-buttons {
      z-index: 0;
    }
  }

  &__table {
    margin-top: 50px;

    table {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1500px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    &__form {
      width: 100%;
    }

    &__table {
      width: 100%;
    }
  }

  &__add-item-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.table-column-header-title {
  text-wrap: initial;
}

.audits-list-table {
  margin-bottom: 10px;

  table {
    width: calc(100% - 20px);
  }

  .ant-table-row:hover {
    cursor: pointer;
  }

  .ant-table-row-expand-icon {
    width: 21px;
    height: 21px;

    &::before {
      top: 9px;
    }

    &::after {
      inset-inline-start: 9px;
    }

    &-expanded {
      &::after {
        top: 3px;
      }
    }
  }
}

.ant-popover {
  z-index: 999999;
}

.audits-list-table .ant-table::-webkit-scrollbar {
  height: 8px;
}

.audits-list-table .ant-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
