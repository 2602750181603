.add-new-patient__owner-select {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.ant-form-item-control-input-content
  .ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.add-new-patient__owner-title {
  &::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.tooltip {
  color: accent-palette(300);
}
