@import '../../assets/sass/variables';

.clinic-info-page {
  .ant-card-cover {
    max-height: 130px;
  }

  .copy-clinic-icon {
    font-size: 20px;
    margin-left: 10px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .info-icon {
    font-size: 30px;
    color: #8f8f90;
  }
}

.clinic-info-icon-style {
  width: 50px;
  font-size: 30px;
  color: #8f8f90;
}

.clinic-media-link-container {
  overflow: hidden;
  .clinic-media-link {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
}

.min-width-300 {
  min-width: 300px;
  @media (max-width: 400px) {
    min-width: -webkit-fill-available;
  }
}
