@import '../../../assets/sass/variables';

.ant-layout .ant-layout-header {
  background-color: #0e3048;
  width: 100%;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
}

.logo-desktop {
  display: block;
}

.logo-mobile {
  display: none;
}

@media #{$max991} {
  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
  }

  .ant-layout .ant-layout-header {
    padding-inline: 10px;

    .ant-btn.ant-btn-lg {
      height: 64px;
    }
  }

  .ant-layout-content {
    margin-top: 64px;
  }
}

.ant-layout-sider.navbar {
  max-width: 220px !important;
  min-width: 220px !important;
  width: 220px !important;
  padding-top: 18px;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
  transition: box-shadow 0.2s $animation;
  will-change: box-shadow;
  overflow: auto;
  transition: transform 0.2s $animation;
  will-change: transform, width;
  -webkit-overflow-scrolling: touch;

  .ant-layout-sider-children {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }

  @media #{$max991} {
    display: flex;
    justify-content: flex-end;
    left: 0;
    position: fixed;
    top: 64px;
    bottom: 0;
    transform: translateX(-$vertical-navbar-width);
    transition: transform 0.2s $animation;
    will-change: transform, width;
    z-index: 997;

    &.opened {
      transform: translateX(0);
      z-index: 999;
    }
    .menu-wrapper-btn {
      display: none;
    }
  }

  @media #{$min992} {
    z-index: 900;
    position: fixed;
    height: calc(100vh - 64px);

    transform: translateX(-$vertical-navbar-width);
    transition: transform 0.2s $animation;
    will-change: transform, width;
    z-index: 997;
    transform: translateX(-170px);
    overflow: visible;

    &:not(.menu-wrapped) {
      transform: translateX(0);

      .menu-wrapper-btn {
        right: 10px;
        margin-top: 14px;
      }
    }

    &.menu-wrapped {
      .ant-menu-item {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        margin-left: auto;

        .ant-menu-title-content {
          max-width: 0;
          margin: 0;
        }
      }

      .vertical-menu__clinics-title,
      .vertical-menu__personal-title {
        display: none;
      }

      .ant-menu-item-group:nth-child(1) {
        .ant-menu-item-group-title {
          display: none;
        }
      }

      .menu-wrapper-btn {
        margin-top: -22px;
        right: 13px;
      }

      .ant-menu-item-group-title {
        display: none;
      }
    }

    .menu-wrapper-btn {
      background: transparent;
      border: none;
      padding: 0;
      box-shadow: none;
      height: 20px;
      width: 20px;
      border-radius: 15px;

      img {
        height: 20px;
        width: 20px;
      }

      cursor: pointer;
      position: absolute;
      right: -15px;
      z-index: 998;
    }
  }
}

body {
  @media #{$min992} {
    &:has(.menu-wrapped) {
      .ant-select-dropdown:has(.clinic-select-option) {
        top: -32px !important;
      }
      .ant-layout-sider.navbar {
        margin-top: 36px;
      }

      .ant-menu-item-group-title {
        display: flex;
        justify-content: flex-end;

        .clinic-select {
          width: 50px;

          .ant-select-arrow {
            display: none;
          }

          .ant-select-selector .ant-select-selection-item {
            left: 5px;
            padding-right: 0;
          }
        }
      }
    }
  }
}
