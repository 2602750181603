.bill-appointment-info-block {
  padding-top: 20px;
}

@media only screen and (max-width: 576px) {
  .service-price-container {
    min-width: 100%;
  }

  .fiscal-checkbox-form-item .ant-row {
    flex-wrap: wrap;

    flex-wrap: nowrap;

    .ant-col {
      flex: unset;
    }
  }
}

.bill-print-wrapper {
  display: none;
}