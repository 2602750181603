@import '../../assets/sass/variables';

.client-details {
  .ant-form {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .icon-style {
    width: 50px;
    font-size: 30px;
    color: #171919;
  }

  &__mobile-pets-list {
    width: 90%;
    margin-top: 20px;
    display: flex;
    left: 10px;

    @media #{$min992} {
      display: none;
    }
  }

  &__list-container {
    border-radius: 10px;
    overflow: hidden;
    max-height: 500px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    margin-top: 20px;

    @media #{$max991} {
      display: none;
    }
  }

  &__list {
    max-height: 440px;
    overflow-y: scroll;
    min-height: 317px;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      cursor: pointer;
      padding: 12px 10px !important;
      position: relative;

      .delete-patient-icon {
        display: none;
        height: 16px;
        width: 16px;
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
          opacity: 0.7;
        }
      }

      &:hover {
        background-color: #f9f9f9 !important;
        opacity: 0.9;

        .delete-patient-icon {
          display: block;
        }
      }
    }

    &__add-pet-btn {
      display: flex;
      justify-content: center;
      padding: 10px;
      border-top: 1px solid rgba(5, 5, 5, 0.06);
    }
  }

  &__patient-details {
    padding: 20px 20px 0;

    &__top-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__right-side {
        justify-content: space-between;
        width: calc(100% - 115px);

        &__species {
          margin-right: 4px;

          &::first-letter {
            text-transform: uppercase;
            font-size: 16px;
          }
        }
      }
    }

    &__alergies-container {
      background-color: #ffdd80;
      margin-top: 20px;
      border-radius: 5px;
      padding: 5px 10px;

      .text {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &__vacination-container {
      border: 1px black solid;
      margin-top: 20px;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &__info-container {
      margin-top: 20px;

      &__divider {
        width: 100%;
        margin: 12px 0;
        padding: 0 10px;
        height: 1px;
        background-color: rgba(5, 5, 5, 0.1);
      }
    }
  }

  &__no-pets-list-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 337px;
    justify-content: center;

    .ant-typography {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.capitalizeFirstLetter {
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px; /* Adjust the gap between grid items as needed */
}

@media (min-width: 768px) {
  /* Adjust this breakpoint as needed */
  .grid-container {
    grid-template-columns: 1fr 1fr; /* Two-column layout for wider screens */
  }
}
.left-column,
.right-column {
  position: relative;
  padding: 0;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .left-column::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #ccc;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

.labels {
  font-weight: 600;
  margin-right: 5px;
}

.patient-select-mobile-list {
  &-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__mark-icon {
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }

    &__delete-icon {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

    &__name {
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__icons {
      display: flex;
    }
  }
}

.ant-select-selection-item {
  max-width: calc(100% - 30px);
}

.client-email-row {
  flex-wrap: nowrap;
  #client-email {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 53px);
    display: inline-block;
  }
}
