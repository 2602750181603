.ant-cascader-dropdown {
  width: 300px;
}

.add-appointment-form__doctor-field {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.patient-select
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  max-width: calc(100% - 45px);
  .ant-select-selection-item {
    padding-inline-end: 10px;
    .ant-select-selection-item-content {
      height: 24px;
      line-height: 24px;
      overflow: hidden;
      position: relative;
      display: block;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: fit-content;
    }
    .ant-select-selection-item-remove {
      vertical-align: 6px;
    }
  }
}

.add-appointment-form__multi-select-field .ant-select-selection-overflow {
  max-width: calc(100% - 44px);
  .ant-select-selection-item-remove {
    width: 20px;
  }
}


.single-field-select {
    .ant-select .ant-select-selector .ant-select-selection-item {
        overflow: hidden;
        position: relative;
        display: block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 38px;
    }
}