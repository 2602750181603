.from-item-label-right {
  .ant-form-item-label {
    display: flex;
    justify-content: flex-end;
  }
}

.service-price-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.total-price-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

#total-bill-price {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  text-align: right;
  width: fit-content;
  max-width: 100%;
}
