.locked-tag {
  padding-bottom: 2px;
  padding-left: 3px;
  font-size: 18px;
  align-self: center;
  color: #f4b65d;
}

.locked-label {
  color: #c3c3c3;
}
