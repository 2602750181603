.prices-print-content {
  display: flex;
  flex-wrap: wrap;
  .price-block {
    height: 130px;
    width: calc((100% - 0.4in) / 3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    padding: 5px;
    line-height: normal;
    &:nth-child(21n) {
      margin-bottom: 80px;
    }
    &__title {
      font-weight: 900;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &__manufacturer {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
    &__row {
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 2px;
      height: 65px;
    }
    &__barcode {
      width: 55%;
      display: flex;
      align-self: center;
      svg {
        height: 70px;
      }
    }
    &__price-column {
      width: 45%;
    }
    &__price-row {
      line-height: normal;
      display: flex;
      flex-direction: column;
      font-size: 11px;
      height: min-content;
      align-items: flex-end;
      &__price {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &__price-value {
      font-size: 17px;
      font-weight: bold;
    }
    &__price-currencies {
      font-weight: bold;
      padding-left: 2px;
      font-size: 12px;
      padding-bottom: 2px;
    }
    &__article {
      display: flex;
      font-size: 10px;
      justify-content: end;
      padding-top: 2px;
      height: 12px;
    }
  }
}
@media print {
    @page {
       margin: 0.3in 0.2in 0.3in 0.2in !important;
    }
}

.prices-print-content-wrapper {
  display: none;
}

.print-prices-form {
  .stock-name-box {
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    &__left-side {
      margin-right: 10px;
      max-width: 33%;
      width: -webkit-fill-available;
      &:has(.add-service-form-stock__name) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    &__divider {
      display: block;
      height: 1px;
      background: black;
      max-width: 100%;
      width: -webkit-fill-available;
    }
    .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-label {
        display: none;
      }
    }
  }
  .invitation-creation-form-row {
    margin-bottom: 0.5rem;
  }
  .ant-btn.ant-btn-circle.remove-btn {
    margin-left: 0;
  }
}