@import 'assets/sass/_variables.scss';
@import 'assets/sass/styles';
@import 'react-big-calendar/lib/sass/styles';
@import 'assets/scss/table';

.tsqd-open-btn-container {
  margin-right: 70px;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: accent-palette(300);
}

.ant-spin-dot-item {
  background-color: accent-palette(500) !important;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-submenu:hover::after,
.ant-menu.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-k7429z).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-active::after,
.ant-menu-submenu-active::after,
.ant-menu.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-item-open::after,
.ant-menu.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-submenu-open::after,
.ant-menu.ant-menu-horizontal > .ant-menu-submenu-open::after {
  border-bottom-color: accent-palette(800) !important;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-submenu-selected::after,
.ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: accent-palette(800) !important;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:focus {
  color: accent-palette(300) !important;
}

.ant-pagination-jump-prev .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-link-icon {
  color: accent-palette(300) !important;
}

.ant-table-column-sorter-up.active {
  color: accent-palette(300) !important;
}

.ant-table-column-sorter-down.active {
  color: accent-palette(300) !important;
}

.ant-select-item-option-selected {
  background-color: accent-palette(350) !important;
}

.ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: accent-palette(350) !important;
}

.ant-picker-dropdown .ant-picker-today-btn {
  color: accent-palette(300) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: accent-palette(500) !important;
  border-color: accent-palette(800) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: accent-palette(800) !important;
}

.ant-picker-dropdown:has(.ant-picker-panel-container.ant-picker-date-panel-container) {
  z-index: 998;
  @media (max-height: 800px), screen and (max-width: 800px) {
    font-size: 0.7rem;
    .ant-picker-header > button {
      font-size: 10px;
      min-width: 1.4rem;
    }
    td {
      padding: 2px 0;
      div {
        min-width: 14px;
        height: 14px;
        line-height: 14px;
      }
    }
  }
}

.ant-picker-cell-inner {
  border-color: accent-palette(800) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: accent-palette(500) !important;
}

li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: accent-palette(350) !important;
}

.custom-links {
  color: accent-palette(300) !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:active {
  color: accent-palette(300) !important;
}

.custom-links:hover {
  color: accent-palette(800) !important;
}

.edit-button {
  margin-top: 20px;
}

.calendar-date {
  display: inline-block;
  width: 95px;
  cursor: pointer;

  input {
    cursor: pointer;
  }

  .ant-picker {
    padding: 0 !important;
  }
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) {
  .ant-form-item-row:has(.ant-checkbox) {
    flex-direction: initial;
    align-items: baseline;
    .ant-form-item-control {
      width: initial;
    }
  }
}
.horizontal-label-form-item {
  width: 200px;

  .ant-form-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-form-item-label {
      margin-right: 5px;
      padding-bottom: 0;
    }

    .ant-form-item-control {
      width: 10px;
    }
  }
}

.rbc-calendar {
  .rbc-toolbar {
    .rbc-btn-group {
      &:first-child button {
        margin: 0 5px 0 0;
      }

      button {
        border: none;
        padding: 0;
        margin: 0 0 0 5px;
        background: none;

        &.rbc-active .ant-btn-primary {
          background: #fff;
          color: #000000d9;
          border-color: #d9d9d9;
        }

        &:active,
        &.rbc-active {
          box-shadow: none;
        }
      }

      @media (max-width: 992px) {
        margin-bottom: 5px;
      }
    }

    .rbc-toolbar-label {
      text-align: left;
      padding: 0;
      font-size: 20px;
      @media (max-width: 992px) {
        flex-grow: 0;
      }
    }
  }

  .rbc-event {
    background-color: #336cfb;
  }

  .rbc-current-time-indicator {
    background-color: red;
  }
}

.ant-layout.ant-layout-has-sider.calendar-layout {
  min-height: 500px;
  @media (max-width: 992px) {
    flex-direction: column;

    .ant-layout {
      width: 100%;
    }
  }

  .aside,
  .aside-closed {
    flex: 0 0 330px;
    max-width: 330px;
    min-width: 330px;
    width: 330px;
    position: relative;
    transition: all 0.2s;
    overflow: hidden;
    @media (max-width: 992px) {
      flex: 0;
      margin-bottom: 15px;
    }

    .ant-collapse {
      margin-right: 10px;
      width: 320px;
      @media (max-width: 992px) {
        width: 100%;
        height: auto;
      }
    }

    .ant-picker-calendar {
      width: 285px;
      @media (max-width: 992px) {
        width: 100%;
      }

      .ant-select-selection-item {
        padding-left: 5px;
      }
    }

    .ant-picker-content th {
      text-align: center;
    }

    @media (max-width: 992px) {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  }

  .aside-closed {
    flex: 0 0 0px;
    max-width: 0px;
    min-width: 0px;
    width: 0px;
    position: relative;
    transition: all 0.2s;
    overflow: hidden;
    @media (max-width: 992px) {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 0px;
      min-height: 0px;
      height: 0px;
    }
  }
}

.checkbox-group-vertical {
  .ant-checkbox-group-item {
    width: 100%;
  }
}

.table-column-header-title {
  text-wrap: initial;
}

.colored-label-wrapper {
  display: flex;
  align-items: center;

  .colored-label {
    height: 20px;
    width: 6px;
    margin-right: 3px;
    border-radius: 3px;
    background: #336cfb;
  }
}

.vlx-number-input {
  display: flex;
  align-items: center;
}

.vlx-number-input-disabled {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.ellipsis-overflow-text {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
