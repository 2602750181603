.dashboard-page {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
  .time-cell-row {
    flex-wrap: nowrap;
  }

  .rbc-time-view {
    @media screen and (min-width: 1200px) {
      min-height: calc(100vh - 301px);
    }
  }
}
