.documents-tab {
  min-height: 400px;

  .ant-upload-wrapper {
    display: flex;
    flex-direction: column;
    display: block;
    max-width: 500px;

    .ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-action {
      background: transparent;
    }

    .ant-upload-list {
      padding-top: 10px;
    }

    .ant-btn-primary .ant-btn-icon {
      margin: 0 !important;

      span {
        margin-left: 0;
      }
    }
  }
}
