.input-group-custom{
  .ant-input-group-addon{
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
}

.custom-countries-list-options {
  min-width: 150px !important;
}
