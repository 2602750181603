.add-tool-form__single-select-field {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.add-tool-form__multi-select-field .ant-select-selection-overflow {
  max-width: calc(100% - 44px);

  .ant-select-selection-item-remove {
    width: 20px;
  }
}

.ant-checkbox-wrapper-disabled
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner:after {
  border-color: white;
}

.purchase-price-form-item {
  width: 70%;
  padding-right: 5px;
  display: inline-block;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-col {
  min-width: 300px;
  max-width: 500px;
}

.ant-pagination-options .ant-select.ant-select-outlined.ant-select-single.ant-select-show-search {
  width: 140px;
}
