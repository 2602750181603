@import '../../../assets/sass/variables';

.status-container {
  background-color: #87d2aa;
  border: 3px solid #43a873;
  border-radius: 12px;
  padding: 4px 3px 4px 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: fit-content;

  &__processing {
    background-color: $warning-color;
  }

  &__error {
    background-color: $error-color;
  }

  &__success {
    background-color: $success-color;
  }

  &__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #43a873 transparent transparent transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-left: 7px;
  }

  &__text {
    color: black;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}