@import '../sass/components/_table';

.ant-table-tbody > tr:active:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  transform: scale(1);
  z-index: 1;
  background-color: #daf2ec; //#6399ff;

  > td {
    border-color: transparent;
    background: $_bg;
  }
}

.ant-table-improved-pagination {
  .ant-table-pagination.ant-pagination {
    float: unset !important;
  }
}

.ant-table-expanded-row.ant-table-expanded-row-level-1 .ant-table-wrapper.invoice-items-table {
  padding-left: 0px;

  .ant-table-cell span {
    padding-right: 10px;
  }
}

.client-table.ant-table-wrapper,
.invoice-items-table.ant-table-wrapper,
.stock-items-table.ant-table-wrapper,
.audits-items-table.ant-table-wrapper {
  padding-left: 48px;
  .ant-table .ant-table-thead tr > th {
    background-color: rgba(31, 32, 34, 0.1) !important;
    color: black;
  }
}
