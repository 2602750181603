@import '../../assets/sass/variables';

.clients-list-table {
    table {
        width: calc(100% - 3px);
        margin-bottom: 10px;
    }

    .ant-table-row:hover {
        cursor: pointer;
    }

    .ant-table-row-expand-icon {
        width: 21px;
        height: 21px;

        &::before {
            top: 9px;
        }

        &::after {
            inset-inline-start: 9px;
        }

        &-expanded {
            &::after {
                top: 3px;
            }
        }
    }
}


.clients-list-table .ant-table::-webkit-scrollbar {
    height: 8px;
}

.clients-list-table .ant-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.client-table {
    .ant-table-content {
        max-height: 389px;
        overflow-y: auto;
    }
}