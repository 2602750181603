.permissions-search {
  .ant-input-group
    > .ant-input-group-addon
    .ant-input-search-button.ant-btn:not(.ant-btn-primary):hover {
    color: #0e3048;
    background: #e9e9e9;
    border-color: #0e3048;
  }
  .ant-btn {
    height: 40px;
    border-color: #e9e9e9;
    background: white;
  }
}

.ant-input-search
  .ant-input:hover
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary) {
  outline: none !important;
}
