.shift-details-status-card {
  .ant-statistic-content-value {
    font-size: 16px;
  }

  .ant-card-body {
    min-height: 247px;
  }
}

.shits-collapse-header {
  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
  }
}
