.patient-print-info {
    table {
        margin-top: 5px;
        width: 100%;
        display: table;
        box-sizing: border-box;
        text-indent: initial;
        unicode-bidi: isolate;
        border-spacing: 0;

        tbody {
            display: table-row-group;
            vertical-align: middle;
            unicode-bidi: isolate;
            border-color: inherit;

            td,
            th {
                border: 1px solid silver;
                padding: .5rem .75rem;
                vertical-align: middle;
                border-top: none;
            }

            th {
                width: 30%;
                font-weight: 600;
                border-right: none;
            }
            
            tr {
                &:nth-child(1) {
                    th {
                        border-top-left-radius: 10px;
                        border-top: 1px solid silver;
                    }
                    td {
                        border-top-right-radius: 10px;
                        border-top: 1px solid silver;
                    }
                }
                &:last-child {
                    th {
                        border-bottom-left-radius: 10px;
                        border-bottom: 1px solid silver;
                    }
                    td {
                        border-bottom-right-radius: 10px;
                        border-bottom: 1px solid silver;
                    }
                }
            }
        }
    }
}

@media print {
    body {
        display: table;
        table-layout: fixed;
        padding-bottom: 2.5cm;
        height: auto;
    }
}

.bill-print-content {
    table {
        margin-top: 10px;
        .ant-table-cell {
            min-width: unset;
        }
    }
    .print-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-avatar {
            width: fit-content;
            height: 100px;
            border-radius: 0;
        }
        .quill.full-length-view {
            width: fit-content;
        }
        &__header {
            flex-direction: row;
            justify-content: center;
            gap: 20px;
        }
    }
}

    .preview-document-container {
        border: 1px solid gray;
        border-radius: 5px;
        padding: 10px;
        max-height: 80vh;
        overflow-y: auto;
        margin: 20px 0;
    }

.invoice-print-info {
    &__header {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    &__sum-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media print {
    .simple-view-table {
        .ant-table {
            table {
                margin-top: 0;
                border: 1px solid silver;
                border-radius: 10px;
            }
            tbody tr {
                outline: 1px solid silver!important;
                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    td {
                        &:first-child {
                            border-bottom-left-radius: 10px!important;
                        }
                        &:last-child {
                            border-bottom-right-radius: 10px!important;
                        }
                    }
                    // border-bottom: none;
                }
            }
            thead tr {
                outline: 1px solid silver!important;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            th, td {
                background: transparent!important;
            }
        }
        &:has(.ant-table-footer ) {
            table {
                border-radius: 10px 10px 0 0;
            }
            tbody tr:last-child {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .ant-table-footer {
            border: 1px solid silver;
            border-radius: 0 0 8px 8px;
            background: transparent;
            border-top: none;
        }
    }

    .hide-stock-info-box-view {
        padding: 16px;
        border: 1px solid silver;
        border-radius: 10px;
    }
}