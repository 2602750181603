.save-btn {
  border-radius: 30px;
  margin-left: 12px;
  width: 40px;
}

.changes-status {
  font-weight: 'bold';
  max-height: 30px;
  margin-top: 4px;
  font-weight: 600;

  &.result {
    margin-right: 20px;
    align-items: center;
    align-self: center;
    @media (max-width: 560px) {
      white-space: initial;
      max-height: fit-content;
    }
  }
}

.residual-title {
  font-size: 14;
  font-weight: 600;
  color: '#8a8a8a';
}

.residual-info {
  margin-bottom: 6px;
}

.title {
  font-weight: 600;
  font-size: 15;
}

.status-column.no-package .ant-form-item-label {
  @media (max-width: 767px) {
    display: none;
    margin-bottom: 0;
  }
}

.status-column.package .ant-form-item-label {
  @media (max-width: 991px) {
    display: none;
  }
}
