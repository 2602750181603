@import '../variables';

.ant-modal {
  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-header {
    padding: ($module-rem * 2) ($module-rem * 2) 0;

    .title {
      margin: calc($module-rem / 2);
      line-height: 1;
    }
  }

  .ant-modal-body {
    padding: ($module-rem * 2);
  }

  .ant-modal-footer {
    padding: $module-rem * 2;
  }

  .modal-footer {
    border-radius: 0 0 $shape $shape;
    margin: -$module-rem * 2;
    padding: $module-rem * 2;
    background-color: rgba(#000, 0.05);
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(#000, 0.15);
  }
}

.vlx-modal.ant-modal {
  margin-top: 48px;
  margin-bottom: 48px;
  @media (max-width: 860px) {
    margin: 0;
  }
  button {
    box-shadow: none;
  }
  .ant-modal-header {
    padding: 24px 40px;
    border-bottom: 1px solid #DCE0E4;
    margin-bottom: 0;
    @media (max-width: 860px) {
      padding: 16px 40px;
    }
  }
  .ant-modal-content {
    padding: 0;
    .ant-modal-close {
      top: 24px;
      right: 24px;
      width: 20px;
      height: 20px;
      &:hover {
        background-color: transparent;
      }
    }
    .ant-modal-body {
      padding: 0;
      form {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 162px);
        @media (max-width: 860px) {
          max-height: calc(100vh - 66px);
        }
        .modal-content {
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 29px 20px 29px 40px;
          margin-right: 20px;
          @media (max-width: 860px) {
            padding: 29px 14px 29px 24px;
           margin-right: 10px;
          }
          &::-webkit-scrollbar {
            width: 4px;
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.10);
            width: 4px;
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.20);
          }
          &::-webkit-scrollbar-track {
            box-shadow: none;
          }
        }
      }
    }
  }
}