@media (max-width: 767px) {
  .additional-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-btn {
      margin-bottom: 10px;
    }
  }
}
