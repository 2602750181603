.history-container {
    margin-top: 20px;
    min-height: 400px;

    &__services-title {
        font-weight: 600;
        font-size: 18px;
        color: black;
    }

    &__collapse-item {
        background-color: white;
        border: none;
        .ant-collapse-header {
            padding-left: 0!important;
        }
        .ant-collapse-content {
            border-top: none;
            .ant-collapse-content-box {
                padding-top: 5px;
            }
        }
    }

    &__time {
        color: #bcbcbd;
    }

    &__title {
        color: grey;
        font-size: 20px;
        font-weight: bold;
    }

    p {
        margin-bottom: 5px;
    }

    &__icon-container {
        width: 28px;
        height: 28px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0e3048;

        img {
            height: 13px;
            color: white;
        }
    }

    &__no-data {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        font-weight: 700;
        font-size: 22px;
    }
}

.history-tab {
    .client-details__mobile-pets-list {
        display: block;
        button {
            display: none;
        }
    }
    #client-pets-list {
        display: none;
    }
}