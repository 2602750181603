.composit-search-select-option-search {
  align-items: center;
  gap: 20px;
  margin: 0 0 20px 0;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    line-height: 20px;
  }
  @media (max-width: 1300px) {
    justify-content: flex-start;
  }
  &__date {
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
}

.composit-search-select-option-search-button {
  border-radius: 30px;
  align-self: center;
  background-color: #0e3048;
  color: white;
  padding: 5px 30px;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600;
}

.composit-search-select-option-reset-button {
  border-radius: 30px;
  align-self: center;
  background-color: #0e3048;
  color: white;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600;
}

.composit-search-time-range-button {
  @extend .composit-search-select-option-reset-button;
  border-radius: 5px;
}

.composit-search-time-range-button-selected {
  @extend .composit-search-time-range-button;
  border-color: #0e3048 !important;
  background: white !important;
  color: #0e3048 !important;
}


.composit-search-select-option-search-input {
  margin: 0;
}

.composit-search-select-option-search-select {
  z-index: 996;
  width: auto;
  max-width: 300px;

  .ant-select-selection-overflow-item:has(input) {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 40px);
  }

  .ant-select-selector .ant-select-selection-overflow {
    max-width: calc(100% - 45px);
  }
}

.composit-search-select-option-search-date-picker {
  min-width: 140px;
}
