.purchase-price-form-item {
  width: 70%;
  padding-right: 5px;
  display: inline-block;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-col {
  min-width: 300px;
  max-width: 500px;
}
