@import "../sass/components/select";
@import "../sass/components/input";
@import "../sass/_variables";

.skills-tags-select {
  width: 500px;
  max-width: 500px;

  .ant-select-selection-overflow {
    max-width: 450px;
  }

  .ant-select-selector {
    height: auto !important;
    min-height: 4 * $module;
    max-height: 100px;
    overflow: auto;
  }

  .ant-select-selector::-webkit-scrollbar {
    display: none;
  }
}
