@import '../../assets/sass/variables';

.layout.vertical {
  .app-container {
    padding-left: $vertical-navbar-width;

    @media #{$max991} {
      padding-left: 0;
    }

    .app-search {
      @media #{$max543} {
        display: none;
      }
    }

    .footer {
      left: $vertical-navbar-width;

      @media #{$max991} {
        left: 0;
      }
    }

    &.scrolled {
      .navbar.horizontal {
        box-shadow: 4px 3px 4px rgba(#000, 0.05);
        z-index: 997;
      }
    }
  }

  .menu-title {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
}

.menu-overlay {
  cursor: pointer;
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: $vertical-navbar-width;
  bottom: $module-rem * 6;
  top: 0;
  z-index: 999;
}

.menu-opened {
  font-size: 15px;
  width: 220px;
  margin-bottom: 20px;

  .ant-menu-item {
    //color: black !important;//$menu-item-selected-color;
    .locked-icon {
      opacity: 0.3;
    }
  }

  .ant-menu-item-selected {
    //color: $menu-item-selected-color;
    font-weight: 500;
  }

  .ant-menu-submenu-title {
    color: black !important; //$menu-item-selected-color;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: $menu-item-selected-color;
    font-weight: 500;
  }

  .ant-menu-sub.ant-menu-inline {
    background: none !important;
  }

  .ant-menu-item-selected {
    //transition: all 1s ease;
    color: $menu-item-selected-color;
    background-color: #daf2ec;
  }
}

.vertical-menu {
  margin-top: 5px;

  &.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }

  @media #{$max767} {
    padding-bottom: 60px;
  }

  .ant-menu-item-group-title {
    padding-left: 0;
    padding-right: 0;
  }

  &__clinics-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
  }

  &__personal-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .clinic-select {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;

    .ant-select-item-option-selected {
      background-color: red !important;
    }

    .ant-select-item:hover {
      background-color: red !important;
    }

    .ant-select-selector {
      border-radius: 8px !important;
      border-color: #0e3048 !important;
      background-color: #ffffff !important;

      .ant-select-selection-item {
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 38px;
        // display: block !important;
      }
    }

    .ant-select-arrow {
      padding-right: 3px;
    }
  }
}

.clinic-select-option {
  &:hover {
    background-color: #daf2ec !important;
  }

  &.ant-select-item-option-selected {
    background-color: #daf2ec !important;
  }
  .ant-select-item-option-content {
    display: flex;
  }
  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: fit-content;
    min-width: 30px;
    img {
      height: 30px;
      width: 30px;
      border-radius: 15px;
    }
  }
}

.ant-menu-item-group-title:has(.vertical-menu-clinic-select) {
  @media #{$min992} {
    .clinic-select {
      display: none;
    }
  }
}
