@import '../../assets/sass/variables';

.patient-details {
    .edit-patient-button {
        position: absolute;
        font-size: 20px;
        right: 25px;
        top: 65px;
    }

    .ant-form {
        margin-top: 15px;
    }

    .m-t-20 {
        margin-top: 20px;
    }

    .m-t-10 {
        margin-top: 10px;
    }

    .m-r-5 {
        margin-right: 5px;
    }

    .m-b-10 {
        margin-bottom: 10px;
    }

    .icon-style {
        width: 50px;
        font-size: 30px;
        color: #171919;
    }

    &__name-container {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        article {
            font-size: 24px;

            &:first-of-type {
                margin-right: 5px;
            }
        }
    }

    &__species-container {
        display: flex;
        justify-content: center;
    }

    &__species-field {
        margin-right: 5px;

        &:first-letter {
            text-transform: capitalize;
        }
    }

    .ant-affix {
        right: 52px;
    }

    &__vacination-tab {
        padding: 20px 0 20px 0;
        overflow: auto;

        &__no-data {
            min-height: 235px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__add-item-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.patients-vaccination-table .ant-table::-webkit-scrollbar {
    height: 8px;
}

.patients-vaccination-table .ant-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
}