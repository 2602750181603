@import 'assets/sass/variables';

.sales-list-table {
  .ant-table-row:hover {
    cursor: pointer;
  }
}

.disabled-row {
  .ant-table-cell {
    text-decoration: line-through;
    opacity: 0.5;
    &:has(.payment-cell) {
     text-decoration: none;
     opacity: 1;
    }
  }
}

.sales-stats-row {
  .ant-card {
    margin-bottom: 10px;

    .ant-card-body {
      padding-top: 5px !important;
    }
  }
}
