.name-statuses-form-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}

.stock-row-container {
  display: flex;
  flex-direction: column;

  &__form {
    .inventory-update-buttons {
      z-index: 0;
    }
  }

  &__table {
    margin-top: 50px;

    table {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1500px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    &__form {
      width: 100%;
    }

    &__table {
      width: 100%;
    }
  }

  &__add-item-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.stock-barcode {
  width: 100%;
  display: flex;
  justify-content: center;

  svg {
    max-width: 200px;
  }
}

.stock-details-info-container {
  .name-statuses-form-header {
    justify-content: center;
    margin-bottom: 20px;
    #stock-name {
      margin: 10px 8px 0 0;
    }

    span {
      margin-top: 10px;
    }
  }
}

.stock-row-container__table {
  min-height: 200px;
}

.reassign-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-row {
    gap: 15px;
    @media (min-width: 1310px) {
      flex-wrap: nowrap;
    }
    @media (max-width: 991px) {
      flex-wrap: nowrap;
    }
  }
}

.ant-select.room-select .ant-select-selector span.ant-select-selection-item {
  display: inline-block;
}

.ant-btn.ant-btn-circle.remove-btn {
  margin-left: 20px;
  width: 28px;
  height: 28px;
  min-width: 28px;
  display: inline-block;
}

.ant-btn.ant-btn-circle.locations-remove-btn {
  margin-top: 29px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: inline-block;
}

.minus-btn {
  display: flex;
  justify-content: end;
  width: 100%;
  position: relative;
  bottom: 70px;
}
