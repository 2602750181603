.pet-avatar-select {
  height: 40px;
  .ant-avatar {
    height: 40px;
    width: 40px;
  }
  .clinic-avatar {
    border-radius: 50px;
    width: 50px !important;
    height: 50px !important;
    cursor: pointer;
  }
}

.pet-info-box {
  overflow: hidden;
  width: 100%;
  strong, span {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: normal;
  }
  &__text {
    font-weight: 600;
    padding-top: 3px;
  }
}
