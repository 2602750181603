  button.vlx-button {
    border-radius: 20px;
    height: 40px;
    padding: 10px 16px;
    width: 140px;
    box-shadow: none;
    span {
      color: #C9674D;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  button.vlx-button.vlx-cancel-button.ant-btn.ant-btn-primary {
    border: 1px solid #C9674D;
    background: #FFF;
    &:hover {
      border-color: #FFA39E;
      background-color: white;
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
      span {
        color: #FF7875;
      }
    }
  }
  .vlx-save-button.ant-btn.ant-btn-primary {
    background: #163046;
    border-color: transparent;
    span {
      color: #FFF;
    }
    &:hover:not(:disabled) {
      background-color: #0B2538;
      box-shadow: 0px 2px 0px 0px rgba(14, 48, 72, 0.10);
    }
    &:disabled {
      opacity: 1;
      background: #BFBFBF;
      span {
        color: #FFF;
      }
    }
  }