.employees-pagination {

  .ant-pagination-options {
    max-width: 250px;
    min-width: 110px;

    .ant-select-single:not(.ant-select-customize-input):not(.ant-select-sm),
    .ant-select-multiple:not(.ant-select-customize-input), .ant-select:not(.ant-select-sm) {
      width: 110px
    }

    .ant-pagination-options-quick-jumper {
      margin-top: 4px;
    }
  }
}

.equipment-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
