@import '../../assets/sass/variables';

.pricing-page {
  @media only screen and (min-width: 960px) {
    height: 100%;
  }
  .ant-card.ant-card-bordered.pricing-page__card {
    .ant-card-body {
      padding-bottom: 30px!important;

      .ant-col {
        margin-top: 25px;
        .ant-row {
          gap: 15px;
          align-items: center;
          i {
            font-size: 22px;
          }
          span {
            font-size: 14px;
          }
        }
        .ant-row  + .ant-row  {
          margin-top: 10px;
        }
      }
    }
  }
}