.form-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  padding-bottom: 8px;
}

.provider-icon {
  position: relative;
  top: 1px;

  &:has(> .icofont-location-pin.info-icon) {
    top: 0;
  }
}

.comments-container {
  width: 'fit-content';
  padding-right: 50px;
}

.provider-info-container {
  &__row {
    margin-bottom: 14px;
    &:first-of-type {
      padding-top: 20px;
      .provider-info-container__col-right {
        font-size: 14px;
      }
    }
  }
  &__col-left {
    width: 50px;
    .info-icon {
      font-size: 22px;
    }
  }
  &__col-right {
    font-size: 14px;
    display: inline;
    width: calc(100% - 50px);
  }
  &__edit-button-container {
    display: flex;
    justify-content: end;
  }
}
