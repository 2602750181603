@import '../../assets/sass/variables';

.layout {
  display: block;
  height: 100%;

  .app-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: $footer-height;
      position: relative;
      transition: 0.2s padding 0s $animation;
      -webkit-overflow-scrolling: touch;

      .page-loader {
        align-items: center;
        background: #fff;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 10;
      }

      &.fulfilled .app-loader {
        display: none;
      }

      .main-content-wrap {
        min-height: calc(100% - #{$module-rem * 4});
        margin: 0 auto;
        opacity: 0;
        padding: 20px;
        transition: 0.2s opacity 0s $animation;

        .page-header {
          display: flex;
          justify-content: space-between;

          .page-title {
            color: rgba(main-palette(500), 0.5);
            margin: 0 0 ($module-rem * 2);
            font-size: 2rem;
            width: 100%;
          }

          .page-breadcrumbs {
            color: rgba(main-palette(500), 0.5);
            font-size: 0.8rem;
            line-height: 2;
            margin: 0 0 ($module-rem * 2);
          }
        }
      }

      &.loaded {
        .main-content-wrap {
          opacity: 1;
          transition: 0s opacity 0s $animation;
        }
      }
    }

    .content-overlay {
      background: rgba(main-palette(500), 0.1);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      width: 100%;
      z-index: 990;

      &.show {
        @media #{$max991} {
          visibility: visible;
        }
      }
    }

    //Boxed
    &.boxed {
      .main-content {
        .main-content-wrap {
          position: relative;
          max-width: $boxed-width;
          max-width: var(--boxed-width);
        }
      }
    }
  }
}

.ant-layout .ant-layout-has-sider {
  background-color: white;

  @media #{$min992} {
    padding-top: 64px;
  }
}

.vlx-content-area-unauthorized {
  @media #{$min992} {
    max-height: 100%;
    margin: 0 auto;
    padding: 90px 60px;
  }

  @media #{$max991} {
    max-height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 80px;
  }
  &::has(.terms-and-conditions-page) {
    padding-top: 35px;
  }
}

.landing-page {
  &__block {
    h3,
    &__m-t-90 {
      padding-top: 70px;

      @media #{$max991} {
        padding-top: 25px;
      }
    }

    &-intro {
      align-items: center;

      p {
        font-size: 22px;
        margin-top: 5px;

        @media #{$max991} {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }

      article {
        font-size: 20px;

        @media #{$max991} {
          font-size: 16px;
        }
      }

      .slogan-btns-container {
        margin-top: 20px;

        button:nth-child(1) {
          margin-right: 30px;
          background-color: #f17938;
          border-color: #f17938;
        }

        @media (max-width: 400px) {
          button:first-child {
            margin-bottom: 20px;
          }
        }
      }

      &__right {
        display: flex;
        justify-content: center;

        @media #{$min1200} {
          max-width: 600px;
        }
      }

      .logo {
        height: 90px;
      }

      @media #{$max1199} {
        .logo {
          height: 80px;

          img {
            height: 70px;
            margin-top: 10px;
          }
        }
      }

      @media #{$max991} {
        flex-direction: column-reverse;

        .clinic-img {
          margin-top: 40px;
          margin-bottom: 20px;

          @media #{$max767} {
            width: 75%;
          }

          width: 65%;
        }

        .logo {
          height: 70px;

          img {
            height: 60px;
            margin-top: 10px;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 40px;
    }
  }

  article,
  p {
    text-indent: 0px;
  }

  .basic-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) and (max-width: 1199px) {
      flex-direction: row;
      &:nth-child(odd) {
        .basic-block__img-block {
          margin-right: 40px;
        }
      }
      &:nth-child(even) {
        flex-direction: row-reverse;
        .basic-block__img-block {
          margin-left: 40px;
        }
      }

      &__img-block {
        max-width: 40%;
        &:nth-child(1) {
          min-width: 40%;
        }

        img {
          width: 90% !important;
        }
      }
    }

    &:nth-child(1) {
      @media #{$min992} {
        img {
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }

    article {
      font-size: 18px;

      @media #{$max991} {
        font-size: 16px;
      }
    }

    article + article {
      margin-top: 10px;
    }

    &__img-block {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;
      }

      @media #{$max767} {
        margin-bottom: 30px;

        img {
          width: 70%;
        }
      }

      // @media #{$min992} {
      //   height: calc(((100vw - 180px) * 0.333)*0.9);

      //   img {
      //     height: fit-content;
      //   }
      // }

      @media #{$min1200} {
        height: calc(((100vw - 180px) * 0.292) * 0.9);
        max-height: 490px;
        max-width: 650px;

        img {
          max-width: 80%;
        }
      }
    }
  }

  @media #{$max1199} {
    .basic-block + .basic-block {
      margin-top: 50px;
    }
  }

  &__carousel {
    .slick-track {
      background-color: #2d5274;
    }

    &__item {
      display: flex !important;
      background-color: #2d5274;
      padding: 80px 50px;

      @media #{$max991} {
        padding: 30px 40px;
      }

      .ant-col > img {
        width: 100%;

        @media #{$max991} {
          padding-bottom: 50px;
        }
      }

      &__text-block {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{$max991} {
          min-width: 100%;
        }

        &__row {
          display: flex;
          font-size: 20px;
          font-weight: 600;

          @media #{$max991} {
            font-size: 16px;
          }

          img {
            height: 25px;
            margin-right: 20px;
            margin-top: 7px;
          }
        }
      }
    }
  }

  .ant-steps {
    overflow: hidden;

    &-item {
      &-icon {
        margin-right: 15px;
      }

      &-title {
        font-size: 21px;
        margin-bottom: 5px;
        color: #0e3048 !important;

        @media #{$max991} {
          font-size: 18px;
        }
      }

      &-description {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.7) !important;

        @media #{$max991} {
          font-size: 15px;
        }
      }

      .ant-steps-icon {
        color: #ffffff !important;
      }

      &:nth-child(4) {
        .ant-steps-item-icon {
          background-color: #ff6f2c !important;
        }
      }

      &:nth-child(0),
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ant-steps-item-icon {
          background-color: #0e3048 !important;
          border-color: #0e3048 !important;
        }
      }
    }

    @media #{$max991} {
      flex-direction: column;

      .ant-steps-item-description {
        max-width: calc(100vw - 95px) !important;
        margin-bottom: 20px;
      }

      .ant-steps-item {
        padding-inline-start: 0 !important;
        overflow: visible;
      }
    }
  }

  .ant-collapse {
    &-expand-icon {
      height: 29px !important;
    }

    &-header {
      &-text {
        font-size: 21px;
        font-weight: 500;

        @media #{$max991} {
          font-size: 18px;
        }
      }
    }

    &-content {
      &-box {
        font-size: 18px;

        @media #{$max991} {
          font-size: 15px;
        }
      }
    }
  }
}

.landing-page__carousel__item__text-block__row + .landing-page__carousel__item__text-block__row {
  margin-top: 35px;

  @media #{$max991} {
    margin-top: 20px;
  }
}

.clinic-name {
  font-size: 16px;

  @media #{$min992} {
    display: none;
  }
}

.vlx-content-area {
  padding: 15px;

  .ant-menu-overflow {
    margin-top: -7px;
  }

  @media #{$min992} {
    transition: margin 200ms ease-in;
    max-width: calc(100vw - 220px);
    margin-left: 220px;
  }
}

#root:has(.menu-wrapped) {
  .vlx-content-area {
    transition: margin 200ms ease-out;

    @media #{$min992} {
      max-width: calc(100vw - 45px);
      margin-left: 45px;
    }
  }
}

.ant-layout .ant-layout-header {
  @media #{$min992} {
    padding-right: 20px;
    padding-left: 0px;
  }
}

.header-container {
  height: 100%;
  justify-content: space-between;

  &__left-side {
    flex-wrap: nowrap;
    .header-pipe {
      font-size: 22px;
      font-weight: 500;
      margin-left: 40px;
      margin-right: 25px;
      color: #daf2ec;
      @media #{$max991} {
        display: none;
      }
    }

    .clinic-select-option {
      &__logo-container {
        min-width: 42px;
        height: 42px;
        width: 42px;
        border-radius: 20px;
        img {
          height: 42px;
          width: 42px;
          border-radius: 26px;
        }
      }
      &__name-container {
        font-size: 14px !important;
        font-weight: 500;
        font-weight: 500;
        color: #daf2ec;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ant-select.clinic-select.ant-select-single.ant-select-show-arrow {
      @media #{$max991} {
        display: none;
      }
      width: 235px !important;
      .ant-select-selector {
        &:hover {
          cursor: pointer;
        }

        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
      .ant-select-arrow {
        color: white;
        font-size: 13px !important;
      }
    }
  }
}

body:has(.clinic-select-option__in-header) {
  .ant-select-dropdown {
    margin-top: 100px;
    border-radius: 5px;
    padding: 5px 0;
    .clinic-select-option {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        height: 42px;
        @media #{$max991} {
          height: 40px;
        }
        .clinic-select-option__logo-container {
          min-width: 40px;
          img {
            height: 40px;
            width: 40px;
            border-radius: 26px;
          }
          @media #{$max991} {
            min-width: 35px;
            img {
              height: 35px;
              width: 35px;
            }
          }
        }
        .clinic-select-option__name-container {
          font-size: 15px !important;
          @media #{$max991} {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.ant-layout-has-sider {
  scroll-behavior: smooth !important;
}

.elem-list {
  .header-actions-col__layout-anchors {
    @media #{$max991} {
      display: none;
    }

    .header-link-button {
      padding: 0 5px;
      font-weight: 500;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.elem-list-mobile {
  .header-actions-col__layout-anchors {
    display: block;

    .header-link-button {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0e3048;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.ant-layout-header {
  .logo:has(.logo-desktop) {
    @media #{$max991} {
      display: none;
    }

    margin-left: 45px;
    cursor: pointer;
  }

  .logo:has(.logo-mobile) {
    @media #{$min992} {
      display: none;
    }
  }
}

footer {
  z-index: 996;

  @media #{$min992} {
    transition: margin 200ms ease-in;
    margin-left: 220px;
  }
}

#root:has(.menu-wrapped) {
  footer {
    transition: margin 200ms ease-out;

    @media #{$min992} {
      max-width: calc(100vw - 45px);
      margin-left: 45px;
    }
  }
}

body:has(.vlx-content-area-unauthorized) {
  footer {
    margin-left: 0;
  }
}

.ant-layout-content .ant-spin-nested-loading {
  .ant-spin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 106px);

    .ant-spin-dot.ant-spin-dot-spin {
      font-size: 28px;

      .ant-spin-dot-item {
        height: 12px;
        width: 12px;
      }
    }
  }

  .ant-spin-container.ant-spin-blur {
    opacity: 0;
  }
}

.hidden-on-small {
  display: none;
}

@media (min-width: 991px) {
  .hidden-on-small {
    display: flex;
    align-items: center;
  }
}
