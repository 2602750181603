.add-tool-form__single-select-field {
    .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
        overflow: hidden;
        position: relative;
        display: block;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 38px;
        padding-inline-end: 40px;
    }
}

.add-tool-form__multi-select-field .ant-select-selection-overflow {
    max-width: calc(100% - 44px);

    .ant-select-selection-item-remove {
        width: 20px;
    }
}