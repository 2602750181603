@import '../../assets/sass/variables';

.ant-affix {
    width: auto!important;
    right: 20px;
}

.animated-btn-container {
    display: flex;
    justify-content: flex-end;
    button {
        border-radius: 30px;
        height: 50px;
        .sticky-btn-text {
            @media #{$max543} {
                display: none!important;
            }
            @media only screen and (min-width: 1920px) {
                display: block!important;
            }
        }
    }
}