.ant-cascader-dropdown {
  width: 300px;
}

.add-appointment-form__doctor-field {
  .ant-form-item-control-input-content .ant-select-selector .ant-select-selection-item {
    overflow: hidden;
    position: relative;
    display: block;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 38px;
    padding-inline-end: 40px;
  }
}

.horizontal-label-form-item.blacklisted-container {
  .ant-row.ant-form-item-row {
    flex-direction: row;
    align-items: baseline;
    .ant-col {
      width: initial;
    }
  }
}
